import React, {useState, useEffect} from 'react';
import './Gallery.css';
 
import { GalleryItem, GalleryList } from '../../components';
import { galleryContent } from '../../constants';

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css"; 

     
    
const Gallery = () => {  

  // State
  const [tag, setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

  // State to track the index of the currently displayed image
  const [index, setIndex] = useState(-1);

  // Get the current, next, and previous images based on the index
  const currentImage = galleryContent[index];
  const nextIndex = (index + 1) % galleryContent.length;
  const nextImage = galleryContent[nextIndex] || currentImage;
  const prevIndex = (index + galleryContent.length - 1) % galleryContent.length;
  const prevImage = galleryContent[prevIndex] || currentImage;

  // Event handler to open the lightbox
  const openLightbox = (index) => setIndex(index);
  // Event handler to close the lightbox
  const closeLightbox = () => setIndex(-1);
  // Event handler to move to the previous image
  const handleMovePrev = () => setIndex(prevIndex);
  // Event handler to move to the next image
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    if (tag === 'all') { 
      setFilteredImages(galleryContent);
    } else {
      const filtered = galleryContent.filter((image) =>
        image.tag.toLowerCase() === tag.toLowerCase()
      );
      setFilteredImages(filtered);
    }
  }, [tag]);


  const list = [
    {
      id: "all", 
      title: "all", 
    },
    {
      id: "convertible",
      title: "convertible",
    },
    {
      id: "suv",
      title: "SUV", 
    },
    {
      id: "sports",
      title: "sports car",
    }, 
    {
      id: "minivan",
      title: "mini van",
    }, 
  ];
 
  
  return (
    <div className='gallery'>

      {/* Render gallery tab buttons */}
      <ul className='controls'>
        {list.map((item) => { 
          return(
            <GalleryList
            key={item.id} 
            id={item.id} 
            title={item.title}
            setSelected={setTag}
            active={tag == item.id ? true: false }
            />
          ) 
        })
        }
      </ul> 

      <div className='box-container'>
        {/* Render gallery items */}
        {filteredImages.map((item, index) => {
          return(
            <GalleryItem
            key={item.id}
            id={index}
            image={item.image}
            title={item.title}
            popup={openLightbox}
            /> 

          )
        })
        }

        {/* Render lightbox when index is not -1 */}
        {index !== -1 && (
          <Lightbox
            mainSrc={currentImage.image}
            imageTitle={currentImage.title}
            mainSrcThumbnail={currentImage.image}
            nextSrc={nextImage.image}
            nextSrcThumbnail={nextImage.image}
            prevSrc={prevImage.image}
            prevSrcThumbnail={prevImage.image}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
          
      </div>

    </div>

  )
}

export default Gallery;