import { images } from '../constants'; 

const carCategories = [
    {
        id: 1,
        image: images.carCategory1,
        categoryName: 'Convertible'
    },
    {
        id: 2,
        image: images.carCategory2,
        categoryName: 'Sports car',
    },
    {
        id: 3,
        image: images.carCategory3,
        categoryName: 'SUV',
    },
    {
        id: 4,
        image: images.carCategory4,
        categoryName: 'Mini Van',
    },
    {
        id: 5,
        image: images.carCategory5,
        categoryName: 'HatchBack',
    },
];

export default carCategories;