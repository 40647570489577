import React from 'react';

import { PageTitle } from '../../../components';
import { Services} from '../../../sections';
 
 

const ServicePage = () => {
  return (
    <> 

      {/* Page Title */}
      <PageTitle title={'services'} page={'services'} />

      {/* Services section */}
      <Services />
      
    </>
  ) 
}

export default ServicePage;