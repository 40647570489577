import React from 'react';
import './CarHeader.css';

import Select from 'react-select';

import { FaList } from 'react-icons/fa';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';

import { Link } from 'react-router-dom'; 

  
// Array of Sorting Options   
const sorting = [
    { value: '', label: 'default', isDisabled: true },
    { value: 'featured', label: 'featured' },
    { value: 'latest', label: 'latest' }, 
    { value: 'lowPrice', label: 'low price' }, 
    { value: 'highPrice', label: 'high price' }
];
 
const CarHeader = ({showing, total, sortOption, handleSort }) => {  

    // Find the selected sorting option
    const selectedOption = sorting.find((option) => option.value === sortOption);
  
    return ( 

        <div className='car-intro'>
    
            {/* Showing cars count */}
            <div className='showing'> showing {showing} of {total} cars </div>

            {/* Styles buttons */}
            <div className='styles'>
                <Link to='/Car-Grid'><BsFillGrid3X3GapFill /></Link>
                <Link to='/Car-List'><FaList /></Link>
            </div>

            {/* Sorting Options select input */}
            <Select
                options={sorting}
                value={selectedOption} 
                onChange={handleSort}
                isSearchable={false}
                className="custom-select sorting box"
                classNamePrefix="custom-select-prefix" 
            />
            
        </div>
    ) 
}

export default CarHeader;