import React from 'react'; 
import './Testimonials.css';  

import { Heading } from '../../../components';
import { testiContent } from '../../../constants';

import { FaQuoteLeft } from 'react-icons/fa';  

// import Swiper core and required modules
import { Pagination, Autoplay } from 'swiper'; 

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles  
import 'swiper/css';  
import 'swiper/css/pagination';  
 
 

const Testimonials = () => { 
  return (
    <section className='testimonials'>

      {/* Section heading */}
      <Heading title={'testimonials'} span={`What Our Client's Say`} />

      <div className='testimonial-slider'>
      {/* Swiper component */}
      <Swiper 
        // install Swiper modules
        modules={[Pagination, Autoplay]}
        spaceBetween={15}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true }}
        breakpoints= {{
          0:{ 
            slidesPerView: 1,
          },
          768:{
            slidesPerView: 2,
          }
        }}>
        {/* Map over the testiContent array to render each testimonial */}
        {testiContent.map((testimonial) => { 
          return (
            <SwiperSlide className='testi-item' key={testimonial.id}>

              {/* Testimonials Image */}
              <img src={testimonial.image} alt='testimonial-pic' />

              {/* Quote Icon */}
              <FaQuoteLeft className='quote-left'/>

              {/* Testimonial */}
              <p>{testimonial.content}</p>
              
              {/* Author Information */}
              <div className='text'>
                <h4>{testimonial.name}</h4> {/* Author name */}
                <h6>{testimonial.title}</h6> {/* Author title */}
              </div>

            </SwiperSlide>
          )
          })
        }

      </Swiper>
      </div>
      
    </section>

  )
} 

export default Testimonials