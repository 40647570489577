import React from 'react';
import './Counter.css';

import { counter } from '../../../constants';

import CountUp from 'react-countup';
 
 
 
const Counter = () => { 
  return (  
    <div className='counting'> 
      {/* Render each counter */}
      {counter.map((count) => {
        return (
          <div className='box' key={count.id}>
              <div className='icon'>{count.icon}</div>
              <div className='text'>
                {/* Animate the count */}
                <CountUp className='count' end={count.count} duration={3} suffix={'+'} />
                <h3>{count.title}</h3>
              </div>
          </div>
        ) 
      })
      }
    </div>
  )
}

export default Counter;