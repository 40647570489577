import React from 'react';
import './Car.css';  
 
import { Button } from '../../components'; 
import { calculateAverageRating } from '../../utils'; 

import { FaStar, FaCarSide, FaGasPump } from 'react-icons/fa';
import { BsCalendarDate, BsPeopleFill, BsGearWide } from 'react-icons/bs';
import { IoCarSport } from 'react-icons/io5';

import { Link } from 'react-router-dom';   

     
 
const Car = (props) => {  

  // Destructuring props
  const {image, name, car, content, price, specs, addToCart} = props;

  // Calculate the average rating of the car
  const averageRating = calculateAverageRating(car); 

  return (
    <div className='car-item'>

      {/* Car Image Container */}
      <div className='image'> 
        <img src={image} alt='' /> {/* Car Image */}
        <div className='price'>${price}<span>/day</span></div> {/* Car Price */}
      </div>

      {/* Car Content Container */}
      <div className='content'>
        {/* Car rating */}
        <div className='rating'>
          <div className='icon'>
            {/* Display star icons for rating */}
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </div>
          {/* Display average rating and number of comments */}
          {car.comments.length > 0 ? (
            <h5>{averageRating} ({car.comments.length})</h5>
          ) : (
            <h5>Not rated</h5>
          )}
        </div>
        {/* Link to the individual car page */}
        <Link to={{ pathname: '/Cars/' + name }}   
          location = {{car}}
          state= {car}><h2>{name}</h2> 
        </Link>
        {/* Car Details */}
        <div className='details'> 
          <h3><IoCarSport className='icon' /><span>{specs.carMake}</span></h3>
          <h3><FaCarSide className='icon' /><span>{specs.carType}</span></h3>
          <h3><BsCalendarDate className='icon' /><span>{specs.modelYear}</span></h3>
          <h3><BsPeopleFill className='icon' /><span>{specs.seating}</span></h3>
          <h3><FaGasPump className='icon' /><span>{specs.fuelType}</span></h3>
          <h3><BsGearWide className='icon' /><span>{specs.transmission}</span></h3>
        </div> 
        {/* Car Content */}
        <p>{content}</p>
        {/* Rent Car Button */}
        <button type="submit" onClick={() => addToCart(car)}><Button link={'cart'} title={'rent car'} /> </button>
        
      </div>
    </div>
  )
} 

export default Car;