import React from 'react'
import './Blogs.css';

import { Blog, Heading } from '../../../components';
import { blogContent } from '../../../constants';
import { latestBlogs } from '../../../utils';

  
   
const Blogs = () => {      

  // Get the latest blogs
  const recentBlogs = latestBlogs(blogContent);

  return (
    <section className='blog main' id='blog'>
 
      {/* Section heading */}
      <Heading title={'my blogs'} span={'Recent News & Blogs'} />
 
      <div className='box-container'> 
        {/* Render each recent blog */}
        {recentBlogs.map((blog) => { 
          return( 
            <Blog  
              key={blog.id} 
              image={blog.image} 
              category={blog.category}
              date={blog.date}
              admin={blog.admin}
              heading={blog.heading}
              content={blog.content} 
              blog={blog} 
            />)
          })
        }
      </div>

    </section>
  )
}

export default Blogs;