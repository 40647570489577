import { FaBriefcase, FaCar, FaUsers, FaComment } from 'react-icons/fa';

  
const counter = [
    { 
        id: 1,
        icon: <FaBriefcase />,
        title: 'experience',
        count: '50',
    },
    {
        id: 2,
        icon: <FaCar />,
        title: 'cars',
        count: '500',
    },
    {
        id: 3,
        icon: <FaUsers />,
        title: 'customers',
        count: '1000',
    },
    {
        id: 4,
        icon: <FaComment />,
        title: 'reviews',
        count: '500',
    },
 
];

export default counter;