import React from 'react';
import './BlogSingle.css';

import { BlogSidebar, Comments, LightBox, PageTitle } from '../../../components';
import { blogContent } from '../../../constants';
import { useBlogFilter } from '../../../utils'; 

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaCheck, FaUserCircle } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs'; 
import { GoQuote } from 'react-icons/go';

import { Link, useLocation } from "react-router-dom";
 
    
 
const BlogSingle = () => { 
  
  const { 
    selectedCategory,
    selectedTag,
    handleSearchSubmit,
  } = useBlogFilter(blogContent);

  const location = useLocation();
  const blog = location.state;  

  const paragraphs = blog.paragraph; 
  const blogComments = blog.comments;

  // Handle form submission for leaving a comment
  const handleSubmit = (event) => {

    event.preventDefault(); // Prevent the default form submission behavior

    // Extract form data
    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    // Check if all input fields are filled
    const isFormValid = Object.values(values).every((value) => value.trim() !== '');

    if (isFormValid) {
      // Reset the form fields
      event.target.reset();
    }
  };

  return (   
    <>
      {/* Page title */}
      <PageTitle title={'single blog'} page={'single blog'} />

      {/* Blog Single Section */}
      <section className='blog-single'>

        <div className='blog-container'> 

          {/* Blog Information */}
          <div className='blog-info' key={blog.id}>

            {/* Blog Image Container */}
            <div className='image'>
              <img src={blog.image} alt='Blog' /> {/* Blog Image */}
            </div>

            {/* Blog Content Container */}
            <div className='content'> 

              {/* Blog Details */}
              <div className='details'>
                {/* Blog category */}
                <Link
                  className='category'
                  key={blog.id}
                  to={`/Blogs/category/${blog.category}`}>
                  {blog.category}
                </Link>
                <h3><FaUserCircle className='icon' /><span>by {blog.admin}</span></h3>
                <h3><BsCalendar className='icon' /><span>{blog.date}</span></h3>
              </div>

              {/* Blog Heading */}
              <h3 className='main-heading'>{blog.heading}</h3>

              {/* Blog paragraphs */}
              <p>{paragraphs.para1}</p>  
              <p>{paragraphs.para2}</p> 

              <div className='important'>
                <GoQuote className='icon' />
                <div className='box-container'>
                  {/* Quoted text */}
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati sapiente nostrum libero esse facere neque possimus ab a natus itaque quos aliquam autem, dolore quis ullam accusantium illum totam ducimus.
                  <div className='intro'>
                    <span></span>
                    <h6>mark wood</h6>
                  </div>
                </div>
              </div>

              <div className='blog-gallery'>
                <h4 className='sub-heading'>{blog.subSection1.subHeading}</h4>
                <p>{blog.subSection1.details}</p>
                {/* Blog image gallery */}
                <LightBox images={blog.gallery} className={'blog'} />                
              </div> 

              {/* Blog points */}
              <div className='blog-points'>
                <h4 className='sub-heading'>{blog.subSection2.subHeading}</h4>
                <p>{blog.subSection2.details}</p>
                <ul>
                  {
                    (blog.subSection2.points).map((point) => {
                      return <li key={point.id}><FaCheck className='icon' />{point.title}</li>
                    })
                  }
                </ul>
              </div> 

              <div className='end-details'>

                {/* Render the tags */}
                <div className='tags'>
                  {blog.tags.map((item) => (
                    <Link
                      className='item'
                      key={item.id}
                      to={`/Blogs/tag/${item.title}`}>
                      {item.title}
                    </Link>
                  ))}
                </div>

                {/* Render the share buttons */}
                <div className='share'>
                  <h3>share:</h3>
                  <FaFacebookF className='icon' />
                  <FaLinkedin className='icon' />
                  <FaTwitter className='icon' />
                  <FaInstagram className='icon' />
                </div>

              </div>
            </div> 
          </div>
           
          {/* Comments section */}
          <Comments comments={blogComments} />

          <div className='leave-reply'>
            {/* Leave Reply Form */}
            <form className='form' onSubmit={handleSubmit}> 
              <h3>leave a comment</h3>  
              <div className='input-box'>
                  {/* User Name */}
                  <input type="text" name="name" className='box' id="name" placeholder="name" required />
                  {/* User Email */}
                  <input type="email" name="email" className='box' id="email" placeholder="email" required />
              </div>
              {/* Comment Message */}
              <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
              {/* Submit Button */}
              <button type="submit" name="submit" id="submit" className='btn'>submit</button>
            </form>
          </div>

        </div>

        {/* Blog Sidebar */}
        <BlogSidebar
          selectedCategory={selectedCategory}
          selectedTag={selectedTag}
          handleSubmit={handleSearchSubmit}
        /> 

      </section>

    </>

  )
}

export default BlogSingle;