import React, { useState } from 'react';
import './CarsSearch.css';

import Select from 'react-select';


// Array of Car Make options   
const carMakes = [ 
  { value: '', label: 'car make', isDisabled: true },
  { value: 'bmw', label: 'BMW' },
  { value: 'mercedes', label: 'Mercedes' }, 
  { value: 'porsche', label: 'Porsche' }, 
  { value: 'volkswagen', label: 'Volkswagen' }, 
  { value: 'mazda', label: 'Mazda' },
  { value: 'hyundai', label: 'Hyundai' },
  { value: 'mini', label: 'Mini' },
];

// Array of Car Type options
const carTypes = [
  { value: '', label: 'car type', isDisabled: true },
  { value: 'convertible', label: 'convertible' },
  { value: 'sports', label: 'sports car' },
  { value: 'suv', label: 'SUV' },
  { value: 'mini', label: 'mini van' },
  { value: 'hatchback', label: 'hatchback' },
];

const CarSearch = ({ onFormSubmit }) => {
  
  // State variables
  const [carMake, setCarMake] = useState('');
  const [carType, setCarType] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  // Event handler for Car Make change
  const handleCarMakeChange = (selectedOption) => {
    setCarMake(selectedOption ? selectedOption.value : '');
  };

  // Event handler for Car Type change
  const handleCarTypeChange = (selectedOption) => {
    setCarType(selectedOption ? selectedOption.value : ''); 
  };

  // Event handler for Max Price change 
  const handleMaxPriceChange = (e) => {
    setMaxPrice(e.target.value);
  };

  // Event handler for form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (carMake || carType || maxPrice) {
      onFormSubmit(carMake, carType, maxPrice);
    }
    setCarMake('');
    setCarType('');
    setMaxPrice('');
  };

  return (
    <section className="carSearch">

      {/* Car Search Heading */}
      <h4>search cars</h4>

      {/* Car Search Form */}
      <form className="car-search" onSubmit={handleFormSubmit}>

        {/* Car Make select input */}
        <Select
          options={carMakes}
          value={carMakes.find((option) => option.value === carMake)}
          onChange={handleCarMakeChange}
          className="custom-select box"
          classNamePrefix="custom-select-prefix"
        />

        {/* Car Type select input */}
        <Select
          options={carTypes}
          value={carTypes.find((option) => option.value === carType)}
          onChange={handleCarTypeChange}
          className="custom-select box"
          classNamePrefix="custom-select-prefix" 
        />

        {/* Max Price Input Field */}
        <div className="box">
          <input
            type="number"
            id="price"
            name="price"
            placeholder="max price"
            value={maxPrice}
            onChange={handleMaxPriceChange}
            className="input-field"
            min="0"
          />
        </div>

        {/* Submit button */}
        <div className="box">
          <button type="submit" className="btn">search</button>
        </div>
        
      </form>
    </section>
  );
};

export default CarSearch;