import React from 'react';
import './Logo.css';

import { images } from '../../constants';
 
import { Link } from "react-router-dom";
 
   
 
const Logo = () => {    
  return (

    // Clickable logo that redirects to the home page
    <Link to='/'  className='logo'>

      {/* Render the logo image */} 
      <img src={images.logo} alt='header-logo' />

      {/* Render the logo name */} 
      <div className='logo-name'>
        <h3><span>Elite</span>Cars</h3> {/* Heading with the company name */}
        <p>car rental service</p> {/* A short description of the company */}
      </div>

    </Link> 
    
  )
}

export default Logo;