import React from 'react';
import './Heading.css';



const Heading = ({title, span}) => { 
  return ( 

    <div className='heading'> 
 
      {/* Sub-Heading title */} 
      <span>{span}</span>

      {/* Heading title */}
      <h2>{title}</h2> 

    </div>

  ) 
} 

export default Heading