import React from 'react';
import './GalleryItem.css';

import { FaExpandAlt } from 'react-icons/fa';


// Gallery item component 
const GalleryItem = ({ id, image, title, popup }) => {

  return (
    <div className={`gallery-item`} key={id}>
 
      {/* Gallery Image */} 
      <img src={image} alt={title} />
      
      {/* Gallery Content */}
      <div className='content'>
        <h4>{title}</h4> {/* Display the title */}
        {/* Trigger the popup function, When clicked */}
        <a onClick={() => { popup(id) }}>
          <FaExpandAlt className='icon' /> {/* Render the expand icon */}
        </a>
      </div>
      
    </div>
  );
}

export default GalleryItem;