import React from 'react';
import './ServiceSingle.css';

import { LightBox, PageTitle, SidebarHeading } from '../../../components';
import { serviceContent } from '../../../constants';

import { FaArrowRight, FaCheck, FaCaretRight } from 'react-icons/fa';
 
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom'; 

    
   
function ServiceSingle() { 

    const location = useLocation();
    const service = location.state;

    const paragraphs = service.paragraph; 

    return ( 
        <>
            {/* Page Title */}
            <PageTitle title={'service single'} page={'service single'} />
            
            <section className='service-single'>

                {/* Sidebar */}
                <div className='sidebar'> 

                    <div className='category sidebar-item'>
                        {/* Sidebar heading */}
                        <SidebarHeading title='all services' />
                        <div className='box-container'>
                        {/* Render links to all services */}
                        {serviceContent.map((service) => {
                            return(
                                <Link to={{
                                    pathname: '/Services/' + service.title,
                                    }}     
                                    location = {{service}}
                                    state= {service}
                                    key={service.id}>
                                    <span><FaCaretRight className='icon' />{service.title}</span>
                                </Link>
                            )
                        })
                        }
                        </div>
                    </div>
                </div>
                
                {/* Service information */}
                <div className='service-info'>
                    <div className='image'>
                        <img src={service.image} alt={service.title} /> {/* Service Image */}
                    </div>
                    <div className='content'>
                        <h3 className='main-heading'>{service.title}</h3> {/* Service Title */}

                        {/* Render paragraphs */}
                        <p>{paragraphs.para1}</p>   
                        <p>{paragraphs.para2}</p> 

                        {/* Service gallery */}
                        <LightBox images={service.gallery} className={'service'} />

                        {/* Render service procedure */}
                        <h3 className='sub-heading'>How we work?</h3>
                        <ul className='service-procedure'>
                            {
                                (service.procedure).map((item) => {
                                    return <li key={item.id}><FaArrowRight className='icon' />{item.point}</li>
                                })
                            }
                        </ul> 

                        {/* Render service importance */}
                        <h3 className='sub-heading'>service importance:</h3>
                        <ul className='service-importance'> 
                        {
                            (service.importance).map((item) => {
                                return <li key={item.id}><FaCheck className='icon' />{item.point}</li>
                            })
                        }
                        </ul>
                    
                    </div>

                </div>

            </section>
        </>
    )
}

export default ServiceSingle;
 