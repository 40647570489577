import React from 'react';
import './Checkout.css';

import { PageTitle, Heading, CartSummary } from '../../../components';
import { payment } from '../../../constants';

    
  
const Checkout = ({cart}) => {   

  const handleSubmit = (event) => {

    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    // Check if all input fields are filled
    const isFormValid = Object.values(values).every((value) => value.trim() !== '');

    if (isFormValid) {
      // Reset the form fields
      event.target.reset();
    }
    
  };

  return (
    <> 
      {/* Page title */}
      <PageTitle title={'checkout'} page={'checkout'} />

      {/* Checkout section */}
      <section className='checkout'>

        {/* Checkout form */}
        <form onSubmit={handleSubmit}>

          <div className='box-1'>  

            {/* Contact Details */}
            <div className='contact details checkout-item'>
              <Heading title='contact details' />
              <div className='form'>
                <div className='input-box'>
                  {/* First Name */}
                  <input type="text" name="first-name" placeholder="first name" id="first-name" className='box' required />
                  {/* Last Name */}
                  <input type="text" name="last-name" placeholder="last name" id="last-name" className='box' required />
                </div>
                {/* Company */}
                <input type="text" name="company" placeholder="company" id="company" className='box' required />
                {/* Country */}
                <input type="text" name="country" placeholder="country" id="country" className='box' required />
                {/* Address */}
                <input type="text" name="address" placeholder="address" id="address" className='box' required />
                <div className='input-box'>
                  {/* City */}
                  <input type="text" name="city" placeholder="town/city" id="city" className='box' required />
                  {/* ZIP */}
                  <input type="text" name="zip" placeholder="postcode/ZIP" id="zip" className='box' required />
                </div>
                <div className='input-box'>
                  {/* Phone Number */}
                  <input type="number" name="number" placeholder="phone no" id="number" className='box' required />
                  {/* Email Address */}
                  <input type="email" name="email" placeholder="email address" id="email" className='box' required />
                </div>
              </div>
            </div>

            {/* Booking Details */}
            <div className='booking details checkout-item'>
              <Heading title='booking details' />
              <div className="form">
                <div className="input-box">
                  {/* From Address */}
                  <input type="text" name="from-address" placeholder="from address" id="from-address" className="box" required />
                  {/* To Address */}
                  <input type="text" name="to-address" placeholder="last name" id="to-address" className="box" required />
                </div>
                <div className="input-box">
                  {/* Pickup Date */}
                  <input type="text" name="pickup-date" placeholder="pickup date" id="pickup-date" className="box" onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => (e.target.type = 'text')} />
                  {/* Pickup Time */}
                  <input type="text" name="pickup-time" placeholder="pickup time" id="pickup-time" className="box" onFocus={(e) => (e.target.type = 'time')} onBlur={(e) => (e.target.type = 'text')} />
                </div>
                <div className="input-box">
                  {/* Dropoff Date */}
                  <input type="text" name="drop-date" placeholder="dropoff date" id="dropoff-date" className="box" onFocus={(e) => (e.target.type = 'date')} onBlur={(e) => (e.target.type = 'text')} />
                  {/* Dropoff Time */}
                  <input type="text" name="drop-time" placeholder="dropoff time" id="dropoff-time" className="box" onFocus={(e) => (e.target.type = 'time')} onBlur={(e) => (e.target.type = 'text')} />
                </div>
                {/* Message */}
                <textarea cols="30" rows="10" name="comment" className="box" id="comment" placeholder="message"></textarea>
              </div>
            </div>


          </div>

          <div className='box-2'>

            {/* Payment Methods */}
            <div className='payment-methods checkout-item'>
              <Heading title='payment methods' />
              <div className='box-container'>
                { 
                  payment.map((item) => {
                    return(
                      <div className='item' key={item.id}>
                        {/* Payment Method Radio Button */}
                        <input type="radio" id={item.paymentID} name="payment" value={item.value}/>
                        {/* Payment Method Label */}
                        <label htmlFor={item.paymentID}>{item.title}</label>
                        <div className='payment-body active'>
                          {/* Payment Method Content */}
                          <p>{item.content}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div> 

            {/* Cart Total */}
            <div className='cart-total checkout-item'>
              {/* Cart Total Heading */}
              <Heading title='cart total' />
              {/* Cart Summary */}
              <CartSummary cart={cart} />
            </div>

            {/* Submit Button */}
            <button type="submit" name="submit" id="submit" className='btn'>place order</button>

          </div>
        </form>

      </section>
    </>
  )
}

export default Checkout 