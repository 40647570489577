import React from 'react'; 
import './About.css';

import { Heading, Button } from '../../../components';
import { images } from '../../../constants';
   
 

const About = () => {    
  return ( 
    <section className='about'> 

      <div className='box-container'> 
 
        {/* About images */}
        <img src={images.about} alt='' />

        {/* About content */}
        <div className='content'>
          <div className='text'>
            <Heading span={'know about us'} /> {/* About Sub Heading */}
            <h3>We make your drive memorable.</h3> {/* About Heading */}
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Illum iusto minima quo, recusandae perspiciatis aspernatur, rerum tempora vitae aperiam enim, dicta maxime? Eum repellat velit perspiciatis eligendi rerum voluptate repellendus.</p> {/* About Description */}
            <Button link={'car-list'} title={'discover more'} /> {/* Car List Button */}
          </div>
        </div>
 
      </div> 

    </section>
  )
}

export default About;