import { images } from ".";

const testimonials = [ 
    { 
        id: 1,
        image: images.testimonial1, 
        name: 'Andrew Jones', 
        title: 'Customer', 
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!',
    },
    {
        id: 2,
        image: images.testimonial2,
        name: 'Jenny Olson',
        title: 'Customer', 
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!',
    },
    { 
        id: 3,
        image: images.testimonial3,
        name: 'Robert Anderson',
        title: 'Customer', 
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!', 
    },
    { 
        id: 4,
        image: images.testimonial4,
        name: 'Clare Nicholson',
        title: 'Customer', 
        content: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fuga, nemo doloremque? Laboriosam iure vero inventore eligendi vitae accusamus, fugiat quas libero nisi odio fugit dignissimos debitis minima nostrum necessitatibus. Eius!', 
    },
];

export default testimonials;