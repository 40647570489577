{/*Global*/} 
import logo from '../assets/Logo/Logo.png';
import pageTitle from '../assets/PageTitle/PageTitle.jpg';

 
{/*Home*/}
import home1 from '../assets/Home/Home-1.jpg'; 
import home2 from '../assets/Home/Home-2.jpg';
import home3 from '../assets/Home/Home-3.jpg'; 

import about from '../assets/About/About.jpg';

/*Background*/ 
import counterBg from '../assets/Background/Counter.jpg';
import testimonialsBg from '../assets/Background/Testimonials.jpg';

import testimonial1 from '../assets/Testimonials/Pic-1.jpg';
import testimonial2 from '../assets/Testimonials/Pic-2.jpg';
import testimonial3 from '../assets/Testimonials/Pic-3.jpg';
import testimonial4 from '../assets/Testimonials/Pic-4.jpg';

import team1 from '../assets/Team/Team-1.jpg';
import team2 from '../assets/Team/Team-2.jpg';
import team3 from '../assets/Team/Team-3.jpg';
import team4 from '../assets/Team/Team-4.jpg';


{/*About*/}
import FAQs from '../assets/FAQs/FAQs.jpg';


{/*Service*/}
import service1 from '../assets/Service/Services/Service-1.jpg';
import service1gallery1 from '../assets/Service/Gallery/1.jpg';
import service1gallery2 from '../assets/Service/Gallery/2.jpg';

import service2 from '../assets/Service/Services/Service-2.jpg';
import service2gallery1 from '../assets/Service/Gallery/1.jpg';
import service2gallery2 from '../assets/Service/Gallery/2.jpg';

import service3 from '../assets/Service/Services/Service-3.jpg';
import service3gallery1 from '../assets/Service/Gallery/1.jpg';
import service3gallery2 from '../assets/Service/Gallery/2.jpg';

import service4 from '../assets/Service/Services/Service-4.jpg';
import service4gallery1 from '../assets/Service/Gallery/1.jpg';
import service4gallery2 from '../assets/Service/Gallery/2.jpg';

import service5 from '../assets/Service/Services/Service-5.jpg';
import service5gallery1 from '../assets/Service/Gallery/1.jpg';
import service5gallery2 from '../assets/Service/Gallery/2.jpg';

import service6 from '../assets/Service/Services/Service-6.jpg';
import service6gallery1 from '../assets/Service/Gallery/1.jpg';
import service6gallery2 from '../assets/Service/Gallery/2.jpg';


{/*Gallery*/}
import gallery1 from '../assets/Gallery/Gallery-1.jpg';
import gallery2 from '../assets/Gallery/Gallery-2.jpg';
import gallery3 from '../assets/Gallery/Gallery-3.jpg';
import gallery4 from '../assets/Gallery/Gallery-4.jpg';
import gallery5 from '../assets/Gallery/Gallery-5.jpg';
import gallery6 from '../assets/Gallery/Gallery-6.jpg';
import gallery7 from '../assets/Gallery/Gallery-7.jpg';
import gallery8 from '../assets/Gallery/Gallery-8.jpg';
import gallery9 from '../assets/Gallery/Gallery-9.jpg';
import gallery10 from '../assets/Gallery/Gallery-10.jpg';


{/*Blogs*/}
import blog1 from '../assets/Blog/Blogs/Blog-1.jpg';
import blog1gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog1gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog1comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog1comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog1comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog2 from '../assets/Blog/Blogs/Blog-2.jpg';
import blog2gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog2gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog2comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog2comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog2comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog3 from '../assets/Blog/Blogs/Blog-3.jpg';
import blog3gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog3gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog3comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog3comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog3comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog4 from '../assets/Blog/Blogs/Blog-4.jpg';
import blog4gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog4gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog4comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog4comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog4comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog5 from '../assets/Blog/Blogs/Blog-5.jpg';
import blog5gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog5gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog5comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog5comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog5comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog6 from '../assets/Blog/Blogs/Blog-6.jpg';
import blog6gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog6gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog6comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog6comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog6comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog7 from '../assets/Blog/Blogs/Blog-7.jpg';
import blog7gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog7gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog7comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog7comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog7comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog8 from '../assets/Blog/Blogs/Blog-8.jpg';
import blog8gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog8gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog8comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog8comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog8comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog9 from '../assets/Blog/Blogs/Blog-9.jpg';
import blog9gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog9gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog9comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog9comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog9comment3 from '../assets/Blog/Comments/Pic-3.jpg';

import blog10 from '../assets/Blog/Blogs/Blog-10.jpg';
import blog10gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog10gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog10comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog10comment2 from '../assets/Blog/Comments/Pic-2.jpg';
import blog10comment3 from '../assets/Blog/Comments/Pic-3.jpg';

{/*Cars*/} 
import carCategory1 from '../assets/Car/Category/Category-1.jpg';
import carCategory2 from '../assets/Car/Category/Category-2.jpg';
import carCategory3 from '../assets/Car/Category/Category-3.jpg';
import carCategory4 from '../assets/Car/Category/Category-4.jpg';
import carCategory5 from '../assets/Car/Category/Category-5.jpg';

import car1Gallery1 from '../assets/Car/Cars/Car-1.jpg';
import car1Gallery2 from '../assets/Car/Cars/Car-2.jpg';
import car1Gallery3 from '../assets/Car/Cars/Car-3.jpg';
import car1Gallery4 from '../assets/Car/Cars/Car-4.jpg';
import car1Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car1Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car2Gallery1 from '../assets/Car/Cars/Car-2.jpg';
import car2Gallery2 from '../assets/Car/Cars/Car-3.jpg';
import car2Gallery3 from '../assets/Car/Cars/Car-4.jpg';
import car2Gallery4 from '../assets/Car/Cars/Car-5.jpg';
import car2Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car2Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car3Gallery1 from '../assets/Car/Cars/Car-3.jpg';
import car3Gallery2 from '../assets/Car/Cars/Car-4.jpg';
import car3Gallery3 from '../assets/Car/Cars/Car-5.jpg';
import car3Gallery4 from '../assets/Car/Cars/Car-6.jpg';
import car3Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car3Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car4Gallery1 from '../assets/Car/Cars/Car-4.jpg';
import car4Gallery2 from '../assets/Car/Cars/Car-5.jpg';
import car4Gallery3 from '../assets/Car/Cars/Car-6.jpg';
import car4Gallery4 from '../assets/Car/Cars/Car-1.jpg';
import car4Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car4Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car5Gallery1 from '../assets/Car/Cars/Car-5.jpg';
import car5Gallery2 from '../assets/Car/Cars/Car-6.jpg';
import car5Gallery3 from '../assets/Car/Cars/Car-1.jpg';
import car5Gallery4 from '../assets/Car/Cars/Car-2.jpg';
import car5Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car5Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car6Gallery1 from '../assets/Car/Cars/Car-6.jpg';
import car6Gallery2 from '../assets/Car/Cars/Car-7.jpg';
import car6Gallery3 from '../assets/Car/Cars/Car-8.jpg';
import car6Gallery4 from '../assets/Car/Cars/Car-9.jpg';
import car6Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car6Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car7Gallery1 from '../assets/Car/Cars/Car-7.jpg';
import car7Gallery2 from '../assets/Car/Cars/Car-8.jpg';
import car7Gallery3 from '../assets/Car/Cars/Car-9.jpg';
import car7Gallery4 from '../assets/Car/Cars/Car-10.jpg';
import car7Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car7Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car8Gallery1 from '../assets/Car/Cars/Car-8.jpg';
import car8Gallery2 from '../assets/Car/Cars/Car-9.jpg';
import car8Gallery3 from '../assets/Car/Cars/Car-10.jpg';
import car8Gallery4 from '../assets/Car/Cars/Car-1.jpg';
import car8Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car8Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car9Gallery1 from '../assets/Car/Cars/Car-9.jpg';
import car9Gallery2 from '../assets/Car/Cars/Car-10.jpg';
import car9Gallery3 from '../assets/Car/Cars/Car-1.jpg';
import car9Gallery4 from '../assets/Car/Cars/Car-2.jpg';
import car9Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car9Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import car10Gallery1 from '../assets/Car/Cars/Car-10.jpg';
import car10Gallery2 from '../assets/Car/Cars/Car-1.jpg';
import car10Gallery3 from '../assets/Car/Cars/Car-2.jpg';
import car10Gallery4 from '../assets/Car/Cars/Car-3.jpg';
import car10Review1 from '../assets/Car/Reviews/Pic-1.jpg';
import car10Review2 from '../assets/Car/Reviews/Pic-2.jpg';

import cart1 from '../assets/Car/Cars/Car-1.jpg';
import cart2 from '../assets/Car/Cars/Car-2.jpg';
import cart3 from '../assets/Car/Cars/Car-3.jpg';


const Images = {

    /*Global*/
    logo,
    pageTitle,


    /*Home*/
    home1,
    home2,
    home3,

    about,

    counterBg,
    testimonialsBg,

    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,

    team1,
    team2,
    team3,
    team4,

    /*About*/
    FAQs,


    /*Service*/
    service1,
    service1gallery1,
    service1gallery2,

    service2,
    service2gallery1,
    service2gallery2,

    service3,
    service3gallery1,
    service3gallery2,

    service4,
    service4gallery1,
    service4gallery2,

    service5,
    service5gallery1,
    service5gallery2,

    service6,
    service6gallery1,
    service6gallery2,

    /*Pages*/
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,

    /*Blogs*/
    blog1,
    blog1gallery1,
    blog1gallery2,
    blog1comment1,
    blog1comment2,
    blog1comment3,

    blog2,
    blog2gallery1,
    blog2gallery2,
    blog2comment1,
    blog2comment2,
    blog2comment3,

    blog3,
    blog3gallery1,
    blog3gallery2,
    blog3comment1,
    blog3comment2,
    blog3comment3,

    blog4,
    blog4gallery1,
    blog4gallery2,
    blog4comment1,
    blog4comment2,
    blog4comment3,

    blog5,
    blog5gallery1,
    blog5gallery2,
    blog5comment1,
    blog5comment2,
    blog5comment3,

    blog6,
    blog6gallery1,
    blog6gallery2,
    blog6comment1,
    blog6comment2,
    blog6comment3,

    blog7,
    blog7gallery1,
    blog7gallery2,
    blog7comment1,
    blog7comment2,
    blog7comment3,

    blog8,
    blog8gallery1,
    blog8gallery2,
    blog8comment1,
    blog8comment2,
    blog8comment3,

    blog9,
    blog9gallery1,
    blog9gallery2,
    blog9comment1,
    blog9comment2,
    blog9comment3,

    blog10,
    blog10gallery1,
    blog10gallery2,
    blog10comment1,
    blog10comment2,
    blog10comment3,


    /*Cars*/
    carCategory1,
    carCategory2,
    carCategory3,
    carCategory4,
    carCategory5,

    car1Gallery1,
    car1Gallery2,
    car1Gallery3,
    car1Gallery4,
    car1Review1,
    car1Review2,

    car2Gallery1,
    car2Gallery2,
    car2Gallery3,
    car2Gallery4,
    car2Review1,
    car2Review2,

    car3Gallery1,
    car3Gallery2,
    car3Gallery3,
    car3Gallery4,
    car3Review1,
    car3Review2,

    car4Gallery1,
    car4Gallery2,
    car4Gallery3,
    car4Gallery4,
    car4Review1,
    car4Review2,

    car5Gallery1,
    car5Gallery2,
    car5Gallery3,
    car5Gallery4,
    car5Review1,
    car5Review2,

    car6Gallery1,
    car6Gallery2,
    car6Gallery3,
    car6Gallery4,
    car6Review1,
    car6Review2,

    car7Gallery1,
    car7Gallery2,
    car7Gallery3,
    car7Gallery4,
    car7Review1,
    car7Review2,

    car8Gallery1,
    car8Gallery2,
    car8Gallery3,
    car8Gallery4,
    car8Review1,
    car8Review2,

    car9Gallery1,
    car9Gallery2,
    car9Gallery3,
    car9Gallery4,
    car9Review1,
    car9Review2,

    car10Gallery1,
    car10Gallery2,
    car10Gallery3,
    car10Gallery4,
    car10Review1,
    car10Review2,

    cart1,
    cart2,
    cart3,

};

export default Images;