import React from "react";

import { Footer, Header, ScrollToTop, ScrollTop } from './components';
import { HomePage, ContactUs, AboutUs, TeamPage, TestimonialsPage, Faqs, ServicePage, BlogGrid, BlogList, Checkout, Cart, Login, Register, ForgotPwd, GalleryPage, CarGrid, CarList, CarSingle, BlogSingle, ServiceSingle } from './sections';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useCart } from "./utils/CartUtils";



const App = () => {

  // Use the useCart hook
  const { cart, addToCart, removeFromCart } = useCart(); 

  return (
    <div className='App'> 

      <ScrollToTop />
      <Header />

      <Routes> 
 
        {/*Home*/}
        <Route path='/' element={<HomePage addToCart={addToCart} />} />

        {/*About*/}
        <Route path='/About-us' element={<AboutUs />} /> 
        <Route path='/Team' element={<TeamPage />} />
        <Route path='/Testimonials' element={<TestimonialsPage />} />
        <Route path='/Faqs' element={<Faqs />} />  

        {/*Pages*/}
        <Route path='/Services' element={<ServicePage />} /> 
        <Route path='/Services/:id' element={<ServiceSingle />} />
        <Route path='/Services/Service-Single' element={<ServiceSingle />} /> 
        <Route path='/Gallery' element={<GalleryPage />} />  
        <Route path='/Login' element={<Login />} /> 
        <Route path='/Register' element={<Register />} /> 
        <Route path='/Forgot-pwd' element={<ForgotPwd />} /> 

        {/*Cars*/}
        <Route path='/Car-Grid' element={<CarGrid addToCart={addToCart} />} />  
        <Route path='/Car-List' element={<CarList addToCart={addToCart} />} /> 
        <Route path='/Cars' element={<CarList addToCart={addToCart} />} /> 
        <Route path='/Cars/:id' element={<CarSingle addToCart={addToCart} />} />
        <Route path='/Cars/Car-Single' element={<CarSingle addToCart={addToCart} />} />
        <Route path='/Cart' element={<Cart cart={cart} removeFromCart={removeFromCart} />} />
        <Route path='/Checkout' element={<Checkout cart={cart} />} />
  
        {/*Blogs*/}
        <Route path='/Blog-Grid' element={<BlogGrid />} /> 
        <Route path='/Blog-List' element={<BlogList />} /> 
        <Route path='/Blogs/search/:search' element={<BlogList />} /> 
        <Route path='/Blogs/tag/:tag' element={<BlogList />} /> 
        <Route path='/Blogs/category/:category' element={<BlogList />} /> 
        <Route path='/Blogs/:id' element={<BlogSingle  />} />
        <Route path='/Blogs/Blog-Single' element={<BlogSingle />} />
 
        {/*Contact*/}
        <Route path='/contact-us' element={<ContactUs />} />

      </Routes>

      <Footer />
      <ScrollTop />
      
    </div>
  )
}

export default App;
