import React, { useState } from 'react';
import './CarSingle.css';

import { Button, PageTitle, Comments, FeaturedCars, SidebarHeading } from '../../../components';
import { calculateAverageRating, countCommentsAndReplies } from '../../../utils';

import { FaStar, FaCarSide, FaSuitcaseRolling, FaGasPump, FaCarAlt } from 'react-icons/fa';
import { BsCalendarDate, BsPeopleFill, BsGearWide } from 'react-icons/bs';
import { GiCarWheel, GiFuelTank, GiSteeringWheel, GiSoundOn } from 'react-icons/gi';
import { IoCarSport } from 'react-icons/io5';

import { useLocation } from 'react-router-dom'; 
import ImageGallery from 'react-image-gallery';

  
  
const CarSingle = ({addToCart}) => {

  const [selectedOption, setSelectedOption] = useState('description');

  const location = useLocation();  
  const car = location.state;  

  const specs = car.details;
  const carReviews = car.comments;

  // Calculate the total number of comments and replies for the product
  let totalCommentsAndReplies = 0;
  if (carReviews) {
    totalCommentsAndReplies = countCommentsAndReplies(carReviews);
  }
  
  // Calculate the average rating for the Car
  const averageRating = calculateAverageRating(car);

  // Handle option change for the Car information tabs
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  }; 

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    // Check if all input fields are filled
    const isFormValid = Object.values(values).every((value) => value.trim() !== '');

    if (isFormValid) {
      // Reset the form fields
      event.target.reset();
    }
  };

  // Create an array of images for the image gallery
  const image = [  
    {
      original: car.gallery.image1,
      thumbnail:  car.gallery.image1,
    },
    {
      original: car.gallery.image2,
      thumbnail:  car.gallery.image2,
    },
    {
      original: car.gallery.image3,
      thumbnail:  car.gallery.image3,
    },
    {
      original: car.gallery.image4,
      thumbnail:  car.gallery.image4,
    },
  ];


  return (
    <>
      {/* Page title */}
      <PageTitle title={'car single'} page={'car single'} />

      {/* Car Single Section */}
      <section className='car-single'>

        {/* Car Description */}
        <div className='car-description'>

          <div className='car-container'>

            <div className='image'>
              <ImageGallery items={image} /> {/* Car Image */}
            </div>

            <div className='content'>
              <div className='text'>

                {/* Car Intro */}
                <div className='car-intro'>
                  <h2>{car.name}</h2> {/* Car Name */}
                  <div className='price'>${car.price}/-</div> {/* Car Price */}
                  <div className='rating'>  {/* Car Rating */}
                    <div className='icon'>            
                      <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /> 
                    </div> 
                    {/* Render average rating and comment count */}
                    {carReviews.length > 0 ? (
                      <h5>{averageRating} ({carReviews.length})</h5>
                    ) : (
                      <h5>Not rated</h5>
                    )}
                  </div> 
                </div>

                <div className='box'> 
                  <p>{car.content}</p> 
                  {/* Button to add car to cart */}
                  <button type="submit" onClick={() => addToCart(car)}><Button link={'cart'} title={'rent car'} /></button>
                </div>

                {/* Car Details */}
                <div className='details'>
                  <h3><IoCarSport className='icon' /><span>{specs.carMake}</span></h3>
                  <h3><FaCarSide className='icon' /><span>{specs.carType}</span></h3>
                  <h3><BsCalendarDate className='icon' /><span>{specs.modelYear}</span></h3>
                  <h3><BsPeopleFill className='icon' /><span>{specs.seating}</span></h3>
                  <h3><FaGasPump className='icon' /><span>{specs.fuelType}</span></h3>
                  <h3><BsGearWide className='icon' /><span>{specs.transmission}</span></h3>
                  <h3><FaCarAlt className='icon' /><span>{specs.doors}</span></h3>
                  <h3><GiCarWheel className='icon' /><span>{specs.drivetrain}</span></h3>
                  <h3><GiFuelTank className='icon' /><span>{specs.fuelTank}</span></h3>
                  <h3><FaSuitcaseRolling className='icon' /><span>{specs.cargoSpace}</span></h3>
                  <h3><GiSteeringWheel className='icon' /><span>{specs.steeringType}</span></h3>
                  <h3><GiSoundOn className='icon' /><span>{specs.soundSystem}</span></h3>
                </div>
                    
              </div>
            </div>

          </div>

          <div className='car-info'>

            {/* Product Information Tab */}
            <div className="car-info-tabs">
              {/* Description Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'description' ? 'active' : ''}`}
                onClick={() => handleOptionChange('description')}>
                description
              </button>
              {/* Additional Information Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'additionalInfo' ? 'active' : ''}`}
                onClick={() => handleOptionChange('additionalInfo')}>
                additional information
              </button>
              {/* Reviews Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'reviews' ? 'active' : ''}`}
                onClick={() => handleOptionChange('reviews')}>
                reviews ({totalCommentsAndReplies})
              </button> 
            </div>

            {/* Description Container */}
            {selectedOption === 'description' && (
              <div className="description car-info-gallery">
                <p>{car.description}</p>
              </div>
            )}

            {/* Additional Information Container */}
            {selectedOption === 'additionalInfo' && (
              <div className='additional-info car-info-gallery'>
                
                {(car.additionalInfo).map((item) => {
                  return (
                    <div className='item' key={item.id}>
                      <h3>{item.title}</h3>
                      <span>{item.info}</span>
                    </div>
                  )
                })}
              </div>
            )} 

            {/* Reviews Container */}
            {selectedOption === 'reviews' && (
              <div className='reviews car-info-gallery'>
                {/* Reviews */}
                <Comments comments={carReviews} />
                <div className='car-review'>
                  {/* Leave a Comment Form */}
                  <form className='form' onSubmit={handleSubmit}>
                    <h3>leave a comment</h3>  
                    <div className='input-box'>
                      <input type="text" name="name" className='box' id="name" placeholder="name" required />
                      <input type="email" name="email" className='box' id="email" placeholder="email" required />
                    </div>
                    <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
                    <button type="submit" name="submit" id="submit" className='btn'>submit</button>
                  </form>
                </div>
              </div>
            )}

          </div>
            
        </div>

        {/* featured cars Section */}
        <div className='featured-items'> 

          {/* Sidebar Heading */}
          <SidebarHeading title='featured cars' />

          {/* Render the featured cars */}
          <FeaturedCars addToCart={addToCart} />

        </div>

      </section>
    </>
  )
}

export default CarSingle;