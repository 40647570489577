import React, { useState } from 'react';
import './Faqs.css';

import { PageTitle, FaqItem } from '../../../components';
import { faqsContent, images } from '../../../constants';



const Faqs = () => {
   
  // State to manage the active accordion
  const [activeAccordionId, setActiveAccordionId] = useState(null);

  // Function to handle accordion click event
  const handleAccordionClick = (id) => {
    setActiveAccordionId((prevId) => (prevId === id ? null : id));
  };

  return (
    <>
      {/* Page title */}
      <PageTitle title={'faqs'} page={'faqs'} />

      <section className='faq'>

        {/* FAQ Accordion Container */}
        <div className='accordion-container'>
          {/* Render FaqItem for each FAQ */}
          {faqsContent.map((faq) => (
            <FaqItem
              key={faq.id}
              id={faq.id}
              title={faq.heading}
              content={faq.content}
              isActive={faq.id === activeAccordionId}
              onAccordionClick={handleAccordionClick}
            />
          ))} 
        </div>

        {/* FAQ Image */}
        <div className='image'>
          <img src={images.FAQs} alt='' />
        </div>
      </section>
    </>
  );
};

export default Faqs;