import React from 'react';
 
import { PageTitle, Gallery } from '../../../components';

 

const GalleryPage = () => {
  
  return (
    <>
      {/* Page Title */}
      <PageTitle title={'Gallery'} page={'Gallery'} />

      {/* Gallery Section */}
      <section>
        < Gallery />
      </section>
    </>

  )
}

export default GalleryPage;