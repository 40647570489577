import { useEffect, useState } from 'react';
import { getPopularCars } from '../utils/popularCars';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';

 
// Custom hook for car filtering
export const useCarFilter = (cars, itemsPerPage) => {
 
  // Get the navigation and location hooks
  const navigate = useNavigate(); 
  const location = useLocation();

  // State variables for storing filtered cars, current page, and sort option
  const [matchingCars, setMatchingCars] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOption, setSortOption] = useState('');

  const { search } = useSearchParams();

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const carMake = params.get('carMake') || '';
    const carType = params.get('carType') || '';
    const maxPrice = params.get('maxPrice') || '';

    // Filter and sort the cars based on search criteria and sort option
    let matchingCars = handleSearch(carMake, carType, maxPrice, cars);
    matchingCars = handleSortCars(matchingCars, sortOption);

    // Update the matching cars state
    setMatchingCars(matchingCars);
    
  }, [search, sortOption]);

  // Function to handle sorting of cars based on the selected option
  const handleSortCars = (cars, sortOption) => {
    switch (sortOption) {
      case 'featured':
        return getPopularCars(cars);
      case 'latest':
        return cars.sort((a, b) => b.details.modelYear - a.details.modelYear);
      case 'lowPrice':
        return cars.sort((a, b) => a.price - b.price);
      case 'highPrice':
        return cars.sort((a, b) => b.price - a.price);
      default:
        return cars;
    }
  };

  // Function to filter cars based on search criteria
  const handleSearch = (carMake, carType, maxPrice, cars) => {
    const matchingCars = cars.filter((car) => {
      const isCarMakeMatch = carMake === '' || car.details.carMake.toLowerCase().includes(carMake.toLowerCase());
      const isCarTypeMatch = carType === '' || car.details.carType.toLowerCase().includes(carType.toLowerCase());
      const isPriceMatch = maxPrice === '' || car.price <= maxPrice;
      return isCarMakeMatch && isCarTypeMatch && isPriceMatch;
    });
    return matchingCars;
  };
  
  // Function to handle form submission and update the URL and matching cars
  const handleFormSubmit = (carMake, carType, maxPrice) => {
    const params = new URLSearchParams();
    params.set('carMake', carMake);
    params.set('carType', carType);
    params.set('maxPrice', maxPrice);
    navigate(`/Cars?${params.toString()}`);

    const newMatchingCars = handleSearch(carMake, carType, maxPrice, cars);
    setMatchingCars(newMatchingCars);
  };

  // Function to handle sorting option change
  const handleSort = (selectedOption) => {
    setSortOption(selectedOption ? selectedOption.value : '');
  };

  // 'displayCars' array: The array of cars to be displayed, either the matching cars or all cars, If no search criteria is applied
  const displayCars = matchingCars.length > 0 ? matchingCars : cars;

  // 'startIndex' and 'endIndex': The start and end indices for the current page's range of displayed cars
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // 'displayedCars' array: The subset of cars to be displayed on the current page
  // 'totalPages': The total number of pages based on the number of cars and items per page
  const displayedCars = displayCars.slice(startIndex, endIndex);
  const totalPages = Math.ceil(displayCars.length / itemsPerPage);

  // Updates the current page when a page number is selected
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  

  return {
    matchingCars,
    sortOption,
    currentPage,
    displayCars,
    displayedCars,
    handleFormSubmit,
    handleSort,
    totalPages,
    handlePageChange,
  };
};