import { useState } from "react";


// Custom hook to manage the cart state and operations
export const useCart = () => {

    const [cart, setCart] = useState([]);

    /* 1- Add an item to the cart */
    const addToCart = (item) => {
        // Check if the item already exists in the cart
        if (!cart.some((cartItem) => cartItem.id === item.id)) {
            // If not, add the item to the cart
            setCart((currentCart) => [...currentCart, item]);
        }
    };

    /* 2- Remove an item from the cart */
    const removeFromCart = (item) => {

        setCart((currentCart) => {

            // Find the index of the item to remove
            const indexOfItemToRemove = currentCart.findIndex(
                (cartItem) => cartItem.id === item.id
            );

            // If the item is not found, return the current cart
            if (indexOfItemToRemove === -1) {
                return currentCart;
            }

            // Remove the item from the cart by creating a new array
            return [
                ...currentCart.slice(0, indexOfItemToRemove),
                ...currentCart.slice(indexOfItemToRemove + 1),
            ];

        });
    };

    // Return the cart state and operations 
    return { cart, addToCart, removeFromCart };

};