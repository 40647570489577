import React from 'react';

import { CarsSearch } from '../../../components';

import { useNavigate } from 'react-router-dom';

 

const HomeSearch = () => {  

  const navigate = useNavigate(); 

  const handleSearchSubmit = (carMake, carType, maxPrice) => {

    // Create a new URLSearchParams object to build the search query string
    const searchParams = new URLSearchParams();
    searchParams.set('carMake', carMake);
    searchParams.set('carType', carType);
    searchParams.set('maxPrice', maxPrice);
    
    // Navigate to the Cars page with the search query parameters
    navigate(`/Cars?${searchParams.toString()}`);

  };

  return (
    // Render the CarsSearch component with the handleSearchSubmit function passed as a prop
    <CarsSearch onFormSubmit={handleSearchSubmit} />
  );
  
}

export default HomeSearch;