import { images } from '../constants'; 

const cars = [
    {
        id: 1,
        image: images.car1Gallery1, 
        name: 'Mazda MX-5 Miata',
        price: 150, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car1Gallery1,
            image2: images.car1Gallery2,
            image3: images.car1Gallery3,
            image4: images.car1Gallery4,
        },
        details: {
            carMake: 'Mazda',
            carType: 'Convertible',
            modelYear: '2011',
            seating: '2 seats',
            fuelType: 'gasoline',
            transmission: 'Hybrid',
            doors: '2 doors',
            drivetrain: 'Rear-Wheel Drive',
            fuelTank: '11.9 gal',
            cargoSpace: 'limited trunk',
            steeringType: 'rack-pinion',
            soundSystem: 'high-quality sound',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 2,
        image: images.car2Gallery1, 
        name: 'Porsche 911 Cabriolet',
        price: 300, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        details: {
            carMake: 'Porsche',
            carType: 'Convertible',
            modelYear: '2023',
            seating: '4 seats',
            fuelType: 'gasoline',
            transmission: 'Automatic',
            doors: '2 doors',
            drivetrain: 'Rear-Wheel Drive',
            fuelTank: '16.9 gal',
            cargoSpace: ' 5.75 cubic feet',
            steeringType: 'rack and pinion',
            soundSystem: 'Bose Surround Sound',
        },
        gallery: {
            image1: images.car2Gallery1,
            image2: images.car2Gallery2,
            image3: images.car2Gallery3,
            image4: images.car2Gallery4,
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.8,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.9,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 3,
        image: images.car3Gallery1, 
        name: 'Porsche 911 Gt3',
        price: 500, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car3Gallery1,
            image2: images.car3Gallery2,
            image3: images.car3Gallery3,
            image4: images.car3Gallery4,
        },
        details: {
            carMake: 'Porsche',
            carType: 'sports car',
            modelYear: '2023',
            seating: '2 seats',
            fuelType: 'gasoline',
            transmission: 'automatic',
            doors: '2 doors',
            drivetrain: 'Rear-Wheel Drive',
            fuelTank: '16.9 gal',
            cargoSpace: 'limited luggage space',
            steeringType: 'rack-pinion',
            soundSystem: 'high-quality sound',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.8,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 4,
        image: images.car4Gallery1, 
        name: 'BMW M3',
        price: 150, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car4Gallery1,
            image2: images.car4Gallery2,
            image3: images.car4Gallery3,
            image4: images.car4Gallery4,
        },
        details: {
            carMake: 'BMW',
            carType: 'Sports Car',
            modelYear: '2023',
            seating: '5 seats',
            fuelType: 'gasoline',
            transmission: 'Automatic',
            doors: '4 doors',
            drivetrain: 'Rear Wheel Drive',
            fuelTank: '15.6 Gal',
            cargoSpace: '13 cubic feet',
            steeringType: 'Rack-Pinion',
            soundSystem: 'harman/kardon System',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.6,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 5,
        image: images.car5Gallery1, 
        name: 'Mercedes AMG G63',
        price: 300, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car5Gallery1,
            image2: images.car5Gallery2,
            image3: images.car5Gallery3,
            image4: images.car5Gallery4,
        },
        details: {
            carMake: 'Mercedes',
            carType: 'SUV',
            modelYear: '2023',
            seating: '5 seats',
            fuelType: 'gasoline',
            transmission: 'automatic',
            doors: '4 doors',
            drivetrain: 'drivetrain',
            fuelTank: '26.4 Gal',
            cargoSpace: '68.5 ',
            steeringType: 'rack-pinion',
            soundSystem: 'Burmester Surround Sound',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'feb 11th, 2023',
                rating: 4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Katrina Molloy',
                date: 'may 25th, 2023',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 6,
        image: images.car6Gallery1, 
        name: 'BMW X5',
        price: 300, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car6Gallery1,
            image2: images.car6Gallery2,
            image3: images.car6Gallery3,
            image4: images.car6Gallery4,
        },
        details: {
            carMake: 'BMW',
            carType: 'SUV',
            modelYear: '2023',
            seating: '5 seats',
            fuelType: 'gasoline',
            transmission: 'automatic',
            doors: '5 doors',
            drivetrain: 'All Wheel Drive',
            fuelTank: '21.9 Gal',
            cargoSpace: '72.3',
            steeringType: 'rack and pinion',
            soundSystem: 'Hi-Fi Sound System',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.6,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.7,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 7,
        image: images.car7Gallery1, 
        name: 'Hyundai Staria ',
        price: 150, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car6Gallery1,
            image2: images.car6Gallery2,
            image3: images.car6Gallery3,
            image4: images.car6Gallery4,
        },
        details: {
            carMake: 'Hyundai',
            carType: 'Mini van',
            modelYear: '2023',
            seating: '7 seats',
            fuelType: 'gasoline',
            transmission: 'automatic',
            doors: '4 doors',
            drivetrain: 'Front-Wheel Drive',
            fuelTank: '14.5 gal',
            cargoSpace: '431 L',
            steeringType: 'Electric Power Steering',
            soundSystem: 'Bose sound system',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.7,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 8,
        image: images.car8Gallery1, 
        name: 'Volkswagen Touran',
        price: 100, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car6Gallery1,
            image2: images.car6Gallery2,
            image3: images.car6Gallery3,
            image4: images.car6Gallery4,
        },
        details: {
            carMake: 'Volkswagen',
            carType: 'Mini Van',
            modelYear: '2023',
            seating: '7 seats',
            fuelType: 'petrol/diesel',
            transmission: 'Hybrid',
            doors: '5 doors',
            drivetrain: 'front-wheel drive',
            fuelTank: '15.9 gallons',
            cargoSpace: ' 26.2 cubic feet',
            steeringType: 'rack-pinion',
            soundSystem: 'premium sound system',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.1,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 9,
        image: images.car9Gallery1, 
        name: 'Volkswagen Golf',
        price: 150, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car6Gallery1,
            image2: images.car6Gallery2,
            image3: images.car6Gallery3,
            image4: images.car6Gallery4,
        },
        details: {
            carMake: 'Volkswagen',
            carType: 'hatchback',
            modelYear: '2023',
            seating: '5 seats',
            fuelType: 'Gasoline/Diesel',
            transmission: 'Hybrid',
            doors: '5 doors',
            drivetrain: 'All-wheel drive',
            fuelTank: '14.5 gal',
            cargoSpace: '13.4 cubic feet',
            steeringType: 'rack and pinion',
            soundSystem: 'premium sound system',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.8,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.9,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 10,
        image: images.car10Gallery1, 
        name: 'Mini Cooper',
        price: 100, 
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        gallery: {
            image1: images.car6Gallery1,
            image2: images.car6Gallery2,
            image3: images.car6Gallery3,
            image4: images.car6Gallery4,
        },
        details: {
            carMake: 'Mini',
            carType: 'Hatchback',
            modelYear: '2015',
            seating: '4 seats',
            fuelType: 'gasoline',
            transmission: 'Hybrid',
            doors: '2 doors',
            drivetrain: 'Front Wheel Drive',
            fuelTank: '10.6 gal',
            cargoSpace: '7.5 cubic feet',
            steeringType: 'Rack-Pinion',
            soundSystem: 'Harman Kardon system',
        },
        description: 'Lorem Ipsum Dolor Sit, Amet Consectetur Adipisicing Elit. Placeat, Culpa Doloremque Suscipit Tempore Id A Tenetur Adipisci Aliquid Pariatur Dolores Cumque Voluptas. Laborum Modi Sapiente Aliquid Officia Blanditiis Cum Dolores! Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quod Distinctio Repellendus Nobis Quaerat Architecto Ullam Molestiae Provident Dolor Saepe Consequatur, Nihil Quis Eos Commodi, Ab Eius, Laborum Consequuntur Laudantium! Odit.',
        additionalInfo: [ 
            {id: 1, title: 'Length', info: '4573 mm'},
            {id: 2, title: 'Width', info: '4573 mm'},
            {id: 3, title: 'Height', info: '1279 mm'},
            {id: 4, title: 'Wheelbase', info: '2457 mm'},
            {id: 5, title: 'Airbags', info: '6'},
        ],
        comments: [
            {
                id: 1,
                image: images.car1Review1,
                name: 'Hedley Howarth',
                date: 'Mar 21st, 2022',
                rating: 4.2,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.car1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },    
    
];

export default cars;