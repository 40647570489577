import React from 'react'; 
import './FeaturedCars.css';

import { Car } from '../../components';
import { cars } from '../../constants';
import { getPopularCars } from '../../utils';

// import Swiper core and required modules 
import { Autoplay } from 'swiper';  
 
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
 
// Import Swiper styles  
import 'swiper/css';



const FeaturedCars = ({addToCart}) => {   
   
  // Get the Popular Cars
  const popular = getPopularCars(cars);
  const popularCars = popular.slice(0, 4);

  return (
    <div className='featuredCar-slider'>
      <Swiper 
        // install Swiper modules
        modules={[Autoplay]}
        spaceBetween={10}
        loop={true}
        autoplay={{ delay: 3500, disableOnInteraction: false }}
        breakpoints= {{
          0:{ 
            slidesPerView: 1,
          },
          768:{
            slidesPerView: 2,
          },
          991:{
            slidesPerView: 3,
          }
        }}
      >
        {
          popularCars.map((car, index) => { 
            return (
              <SwiperSlide key={index}> 

                <Car   
                key={index} 
                image={car.image}  
                name={car.name}
                price={car.price}
                specs={car.details}
                car={car}
                addToCart={addToCart} />

              </SwiperSlide>
            )
          })
        }

      </Swiper>
    </div>
  )
}

export default FeaturedCars;