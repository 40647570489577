import React from 'react'; 

import { Heading, FeaturedCars } from '../../../components';

 

const Cars = ({addToCart}) => {    

  return ( 
    <section className='cars'>

      {/* Section heading */}
      <Heading title={'featured cars'} span={'our popular cars'} />

      {/* Popular Cars Component */}
      <FeaturedCars addToCart={addToCart} />

    </section>
  )
}

export default Cars;