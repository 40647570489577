import { FaStar } from 'react-icons/fa';
import { BsHeadset, BsTelephoneFill, BsCalendarCheck } from 'react-icons/bs';
import { MdThumbUp } from 'react-icons/md';
import { IoCarSport } from 'react-icons/io5';   
  
  // Function to render the appropriate icon based on the given iconName
 export const renderIcon = (iconName) => {

    switch (iconName) {
      case 'car':
        return <IoCarSport />;
      case 'star':
        return <FaStar />;
      case 'headset': 
        return <BsHeadset />; 
      case 'phone':
        return <BsTelephoneFill />;
      case 'calendar':
        return <BsCalendarCheck />;
      case 'thumbsUp':
        return <MdThumbUp />;
      default:
        return null;
    }
  };