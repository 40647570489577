import React from 'react';
import './Button.css';

import { Link } from 'react-router-dom';
  
 
// Button component that renders a link with a title
const Button = ({ title, link }) => {

  return (
    // Create a link with the specified title and link path
    <Link to={`/${link}`} className='btn'>{title}</Link>
  );

}

export default Button;