import { images } from '.';
 
const services = [  
    {   
        id: 1, 
        image: images.service1, 
        title: 'variety of cars',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'car',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service1gallery1},
            {id: 2, image: images.service1gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
    {
        id: 2,
        image: images.service2,
        title: 'Best price guarantee',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'star',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service2gallery1},
            {id: 2, image: images.service2gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
    {
        id: 3,
        image: images.service3,
        title: 'Available 24 x 7',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'headset',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service3gallery1},
            {id: 2, image: images.service3gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
    {
        id: 4,
        image: images.service4,
        title: 'Phone reservation',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'phone',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service4gallery1},
            {id: 2, image: images.service4gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
    {
        id: 5,
        image: images.service5,
        title: 'Reservation anytime',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'calendar',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service5gallery1},
            {id: 2, image: images.service5gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
    {
        id: 6,
        image: images.service6,
        title: 'Best Quality Cars',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'thumbsUp',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.service6gallery1},
            {id: 2, image: images.service6gallery2},
        ],
        procedure: [ 
            {id: 1, point: 'Go to the Car Rental sections.'},
            {id: 2, point: 'select the car of your choice.'},
            {id: 3, point: 'proceed to checkout.'},
            {id: 4, point: 'Fill your contact details and booking details.'},
            {id: 5, point: 'Select a suitable payment option.'},
            {id: 6, point: 'Checkout and your car is on the way.'},
        ],
        importance: [ 
            {id: 1, point: 'Flexible Usage and Pricing'},
            {id: 2, point: 'Choice of Vehicles According to Needs'},
            {id: 3, point: 'Maintenance and Servicing'},
            {id: 4, point: '24 Hour Support'},
            {id: 5, point: 'Designated Rental Drivers'},
        ],
    },
 
];

export default services;