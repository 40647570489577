import React from 'react';
import './Category.css';

import { Heading } from '../../../components';
import { carCategories } from '../../../constants';

import { Link } from 'react-router-dom';



const Category = () => {

  return ( 
    <section className='categories'>
      {/* Section heading */}
      <Heading title={'our category'} span={'top car category'} />

      <div className='box-container'>
        {/* Map over the carCategories array to render each category */}
        {carCategories.map((category) => {

          const carType = category.categoryName;

          return (
            <div className={`category-item category-${category.id}`} key={category.id}>
              {/* Category image */}
              <img src={category.image} alt='' />
              {/* Link to the Cars page with the carType as a query parameter */}
              <Link to={`/cars?carType=${encodeURIComponent(carType)}`}>
                <h3>{category.categoryName}</h3> {/* Category Name */}
              </Link>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Category;