import { images } from '../constants'; 

const blogs = [   
    {   
        id: 1, 
        image: images.blog1,
        category: 'latest model',
        date : 'Jan 2, 2023',
        admin: 'admin',
        heading: 'Tips for finding the best car',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        gallery: [ 
            {id: 1, image: images.blog1gallery1},
            {id: 2, image: images.blog1gallery2},
        ],
        tags: [ 
            {id: 1, title: 'travel'},
            {id: 2, title: 'budget'},
            {id: 3, title: 'tips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Karen Marsh',
                date: 'Jan 5, 2023', 
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Nicki Turner',
                        date: 'Jan 22, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'Ken Wadsworth',
                date: 'Feb 5, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 2, 
        image: images.blog2,
        category: 'holidays',
        date : 'jan 25, 2023',
        admin: 'admin',
        heading: 'Car rental tips for the holidays',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        gallery: [ 
            {id: 1, image: images.blog2gallery1},
            {id: 2, image: images.blog2gallery2},
        ],
        tags: [ 
            {id: 1, title: 'holiday'},
            {id: 2, title: 'travel'},
            {id: 3, title: 'tips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog1comment1,
                name: 'Karen Marsh',
                date: 'Jan 29, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment2,
                        name: 'Nicki Turner',
                        date: 'feb 2, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.blog1comment3,
                name: 'Ken Wadsworth',
                date: 'feb 10, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 3, 
        image: images.blog3,
        category: 'lifestyle',
        date : 'feb 5, 2023',
        admin: 'admin',
        heading: 'The ultimate car rental checklist',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        gallery: [ 
            {id: 1, image: images.blog3gallery1},
            {id: 2, image: images.blog3gallery2},
        ],
        tags: [ 
            {id: 1, title: 'safety'},
            {id: 2, title: 'travel'},
            {id: 3, title: 'trips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog3comment1,
                name: 'Karen Marsh',
                date: 'Feb 15, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog3comment2,
                        name: 'Nicki Turner',
                        date: 'mar 2, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog3comment3,
                name: 'Ken Wadsworth',
                date: 'mar 27, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 4, 
        image: images.blog4,
        category: 'travel',
        date : 'feb 15, 2023',
        admin: 'admin',
        heading: 'Essential driving rules for renting cars',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        gallery: [ 
            {id: 1, image: images.blog4gallery1},
            {id: 2, image: images.blog4gallery2},
        ],
        tags: [ 
            {id: 1, title: 'safety'},
            {id: 2, title: 'travel'},
            {id: 3, title: 'tips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog4comment1,
                name: 'Karen Marsh',
                date: 'feb 27, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog4comment2,
                        name: 'Nicki Turner',
                        date: 'mar 5, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog4comment3,
                name: 'Ken Wadsworth',
                date: 'mar 27, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 5, 
        image: images.blog5,
        category: 'holidays',
        date : 'feb 29, 2023',
        admin: 'admin',
        heading: 'explore your desired spots with rental cars',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        gallery: [ 
            {id: 1, image: images.blog5gallery1},
            {id: 2, image: images.blog5gallery2},
        ],
        tags: [ 
            {id: 1, title: 'holiday'},
            {id: 2, title: 'family'},
            {id: 3, title: 'trips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog5comment1,
                name: 'Karen Marsh',
                date: 'mar 2, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog5comment2,
                        name: 'Nicki Turner',
                        date: 'mar 15, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog5comment3,
                name: 'Ken Wadsworth',
                date: 'apr 27, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 6, 
        image: images.blog6,
        category: 'airport',
        date : 'apr 7, 2023',
        admin: 'admin',
        heading: 'experience the best airport transfer service',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: {
            quote: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
            source: 'mark wood'
        },
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog6gallery1},
            {id: 2, image: images.blog6gallery2},
        ],
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        tags: [ 
            {id: 1, title: 'safety'},
            {id: 2, title: 'trips'},
            {id: 3, title: 'holiday'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'Karen Marsh',
                date: 'apr 15, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Nicki Turner',
                        date: 'apr 27, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Ken Wadsworth',
                date: 'may 6, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 7, 
        image: images.blog7,
        category: 'latest model',
        date : 'apr 22, 2023',
        admin: 'admin',
        heading: 'How to Choose the Right Rental Car',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: {
            quote: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
            source: 'mark wood'
        },
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog7gallery1},
            {id: 2, image: images.blog7gallery2},
        ],
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        tags: [ 
            {id: 1, title: 'safety'},
            {id: 2, title: 'family'},
            {id: 3, title: 'trips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'Karen Marsh',
                date: 'may 2, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Nicki Turner',
                        date: 'may 15, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Ken Wadsworth',
                date: 'may 22, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 8, 
        image: images.blog8,
        category: 'lifestyle',
        date : 'may 2, 2023',
        admin: 'admin',
        heading: 'Essential Accessories for a Road Trip',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: {
            quote: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
            source: 'mark wood'
        },
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog8gallery1},
            {id: 2, image: images.blog8gallery2},
        ],
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        tags: [ 
            {id: 1, title: 'holiday'},
            {id: 2, title: 'trips'},
            {id: 3, title: 'tips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'Karen Marsh',
                date: 'may 7, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Nicki Turner',
                        date: 'may 20, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Ken Wadsworth',
                date: 'may 25, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 9, 
        image: images.blog9,
        category: 'airport',
        date : 'may 20, 2023',
        admin: 'admin',
        heading: 'Saving Money on Rental Car Expenses',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: {
            quote: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
            source: 'mark wood'
        },
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog9gallery1},
            {id: 2, image: images.blog9gallery2},
        ],
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        tags: [ 
            {id: 1, title: 'budget'},
            {id: 2, title: 'family'},
            {id: 3, title: 'trips'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'Karen Marsh',
                date: 'may 25, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Nicki Turner',
                        date: 'Jun 2, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Ken Wadsworth',
                date: 'Jun 8, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
    {   
        id: 10, 
        image: images.blog10,
        category: 'travel',
        date : 'jun 1, 2023',
        admin: 'travel',
        heading: 'Unusual or Unique Rental Car Experiences',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: {
            quote: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
            source: 'mark wood'
        },
        paragraph: {
            para1: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            para2: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog10gallery1},
            {id: 2, image: images.blog10gallery2},
        ], 
        subSection1: {
            subHeading: 'Benefits Of Renting A Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
        },
        subSection2: {
            subHeading: 'Steps To Find The Best Car',
            details: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            points: [ 
                {id: 1, title: 'Assess Your Lifestyle Needs'},
                {id: 2, title: 'Always compare rates booking direct'},
                {id: 3, title: 'Decline insurance for your rental car'},
                {id: 4, title: 'Look for membership discounts and coupons'},
                {id: 5, title: 'Pay for your rental car up front'},
                {id: 6, title: 'Pump your own gas'},
            ],
        } ,
        tags: [ 
            {id: 1, title: 'holiday'},
            {id: 2, title: 'travel'},
            {id: 3, title: 'budget'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'Karen Marsh',
                date: 'Jun 2, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog6comment2,
                        name: 'Nicki Turner',
                        date: 'Jun 5, 2023',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
                
            }, 
            {
                id: 2,
                image: images.blog6comment3,
                name: 'Ken Wadsworth',
                date: 'Jun 8, 2023',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [],
            },
        ]
    },
];

export default blogs;