import React from 'react';
import './Footer.css';

import { Logo } from '../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { BsMapFill } from 'react-icons/bs';
import { FaCaretRight } from 'react-icons/fa';
  
import { Link } from 'react-router-dom';
 
   
  
const Footer = () => {  
  
  return (
    <footer className='footer'>

      <div className='box-container'>

        {/* About Section */}
        <div className='footer-item'> 
          <Logo /> 
          <p>We provide Best cars with the best prices. We are expert in car rental. Enjoy your holidays with us. We make your drive memorable. We care for your safety.</p>
          <div className='social'>
            <a href="#"><FaFacebookF className='icon' /></a>
            <a href="#"><FaTwitter className='icon' /></a>
            <a href="#"><FaInstagram className='icon' /></a>
            <a href="#"><FaLinkedin className='icon' /></a>
          </div>
        </div>

        {/* Useful Links Section */}
        <div className='footer-item'>
          <h2>useful links</h2>
          <div className='info links pages'> 
            <div className='links-item'>
              <p><FaCaretRight className='icon' /><Link to='/about-us'>about</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/Team'>team</Link></p> 
              <p><FaCaretRight className='icon' /><Link to='/Testimonials'>testimonials</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/Faqs'>FAQs</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/Services'>services</Link></p>
            </div> 
            <div className='links-item'>
              <p><FaCaretRight className='icon' /><Link to='/Gallery'>gallery</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/Car-List'>cars</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/Blog-List'>blogs</Link></p>
              <p><FaCaretRight className='icon' /><Link to='/contact-us'>contact</Link></p>
            </div>
          </div>
        </div> 

        {/* Contact Info Section */}
        <div className='footer-item'>
          <h2>contact info</h2>
          <div className='info connect'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><FaEnvelope className='icon' /><span className='gmail'>abc@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
        </div>

      </div>

      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;