import React from 'react'; 

import { Heading, Gallery } from '../../../components';



const GallerySection = () => {    
  return ( 
    <section> 

      {/* Section heading */}
      <Heading title={'our gallery'} span={'Most Famous Cars'} />
      
      {/* Gallery Component */}
      <Gallery /> 
      
    </section>
  )
}

export default GallerySection;