import React from 'react';
import './Login.css'; 

import { PageTitle } from '../../../components';

import { FaUserAlt, FaEnvelope, FaLock } from 'react-icons/fa'; 
import { Link } from 'react-router-dom';
 
   
  
const Login = () => { 
  
  const handleSubmit = (event) => {

    event.preventDefault(); // Prevent the default form submission behavior

    const formData = new FormData(event.target);
    const values = Object.fromEntries(formData.entries());

    // Check if all input fields are filled
    const isFormValid = Object.values(values).every((value) => value.trim() !== '');

    if (isFormValid) {
      // Reset the form fields
      event.target.reset();
    }

  };

  return (  
    <>  
      {/* Page Title */}
      <PageTitle title={'login'} page={'login'} /> 

      <section className='login'>

        {/* Login Form */}
        <form className='account-form' onSubmit={handleSubmit}>

          {/* User Icon */}
          <div className='icon'><FaUserAlt /></div>

          {/* Heading for the Login Form */}
          <h3>login</h3> 

          {/* Email Input */}
          <div className='input-field'>
            <label htmlFor='email'><FaEnvelope /></label>
            <input type="email" name="email" placeholder="enter your email" id="email" className='box' />
          </div>

          {/* Password Input */}
          <div className='input-field'>
            <label htmlFor='password'><FaLock /></label> 
            <input type="password" name="password" placeholder="enter your password" id="password" className='box' />
          </div>

          {/* Remember me and Forgot password */}
          <div className='info'>

            {/* Remember me Checkbox */}
            <div className='remember'>
              <input type="checkbox" name="remember" id="remember-me" />
              <label htmlFor="remember-me"> remember me </label>
            </div>

            {/* Forgot password Link */}
            <div className='forgot'>
              <Link to='/forgot-pwd' className='link'>forgot password?</Link>
            </div>

          </div>

          {/* Login Button */} 
          <button type='submit' name='submit' id='submit' className='btn'>Login</button>

          {/* Registration Link */}
          <p>don't have an account? <Link to='/register' className='link'>register</Link></p>
      
        </form>
      </section> 
    </>

  )
}

export default Login;