import React from 'react';
import './BlogList.css';

import { PageTitle, BlogSidebar, Blog, PagesNo } from '../../../components';
import { blogContent } from '../../../constants';
import { useBlogFilter } from '../../../utils';

   

const BlogList = () => {    

  const itemsPerPage = 4;

  // Fetch filtered and paginated blog data using custom hook
  const {
    selectedCategory,
    selectedTag, 
    filteredBlogs,
    searchKeyword,
    currentPage,
    handleSearchSubmit,
    displayedBlogs,
    totalPages,
    handlePageChange,
  } = useBlogFilter(blogContent, itemsPerPage);
   
  return (
    <>
      {/* Page title */}
      <PageTitle title={'blog list'} page={'blog list'} />
      
      <section className='blog list'>

        {/* Blog sidebar */}
        <BlogSidebar 
          selectedCategory={selectedCategory}
          selectedTag={selectedTag}
          handleSubmit={handleSearchSubmit}
        />

        <div className='blog-container list'>
          {/* Check if there are filtered blogs */}
          {filteredBlogs.length === 0 ? (
            // Render "No result" message if no filtered blogs
            <div className='no-found'>No Result For "{searchKeyword}".</div>
          ) : (
            <>
              {/* Displayed blog items */}
                {displayedBlogs.map((blog, index) => {
                return( 
                  <Blog   
                    key={index} 
                    image={blog.image} 
                    category={blog.category}
                    date={blog.date}
                    admin={blog.admin}
                    heading={blog.heading}
                    content={blog.content} 
                    blog={blog} 
                  />
                );
              })}
              {/* Pagination */}
              <PagesNo
                currentPage={currentPage}
                totalPages={totalPages}
                handlePageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </section>
    </>

  )
}

export default BlogList;