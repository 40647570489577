import React from 'react';
import './Blog.css';

import { FaUserCircle} from 'react-icons/fa'; 
import { BiCalendarAlt } from 'react-icons/bi';

import { Link } from 'react-router-dom'; 

   

const Blog = (props) => {  

  // Destructuring props
  const {id, image, category, date, admin, heading, content, blog} = props;

  return (  

    <div className='blog-item' key={id}>

      {/* Blog image Container */}
      <div className='image'>

        {/* Blog image */}
        <img src={image} alt='Blog' />
        {/* Blog category */}
        <Link 
          to={`/Blogs/category/${category}`}         
          className='category'
          key={id}>{category}
        </Link> 
        
      </div>

      {/* Blog content Container */}
      <div className='content'>

        {/* Link to the individual blog post */}
        <Link to={{ 
            pathname: '/Blogs/' + heading,
          }} 
          location = {{blog}}
          state= {blog}
          className='main-heading'>{heading}
        </Link>

        {/* Blog content */}
        <p>{content}</p> 

        {/* blog Details section */}
        <div className='details'>
          <h3><FaUserCircle className='icon' /><span>by {admin}</span></h3> {/* blog admin */}
          <h3><BiCalendarAlt className='icon' /><span>{date}</span></h3> {/* blog date */}
        </div> 

      </div>

    </div>
  )
}

export default Blog;