import React from 'react';
import './AboutUs.css';

import { PageTitle } from '../../../components';
import { About, Testimonials, Counter, Team } from '../../../sections';


 
const AboutUs = () => {
  return ( 
    <>
    
      {/* Page title */}
      <PageTitle title={'about us'} page={'about'} />

      <section className='about-us'>
        
        {/* About section */}
        <About />

        {/* Counter section */}
        <Counter />

        {/* Team section */}
        <Team />

        {/* Testimonials section */}
        <Testimonials />

      </section>
    </>
  );
}

export default AboutUs;