import { images } from '../constants';

const team = [ 
    {
        id: 1,
        image: images.team1,
        name: 'Bruce Taylor',
        title: 'Founder & CEO',
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
    }, 
    {
        id: 2,
        image: images.team2,
        name: 'Shirley Cowles',
        title: 'Manager',
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
    }, 
    {
        id: 3,
        image: images.team3,
        name: 'Richard Collinge',
        title: 'Director',
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
    }, 
    {
        id: 4,
        image: images.team4,
        name: 'Jill Saulbrey',
        title: 'sales manager',
        accounts: {
            facebook: 'https://www.facebook.com/',
            twitter: 'https://twitter.com/',
            instagram: 'https://www.instagram.com/',
            youtube: 'https://www.youtube.com/',
        },
    }, 
 
];

export default team;