import React from 'react';
import {Home, About, Counter, Services, Team, Testimonials, Blogs, GallerySection, Category, HomeSearch, Cars} from '../../sections'; 

 
const HomePage = ({addToCart}) => { 

  return ( 
    <div className='homepage'>
      <Home /> {/* Render the Home section */}
      <HomeSearch /> {/* Render the CarSearch component */}
      <About /> {/* Render the About section */}
      <Services /> {/* Render the Services section */}
      <Counter /> {/* Render the Counter section */}
      <Category /> {/* Render the Car Category section */}
      <GallerySection /> {/* Render the Gallery section */}
      <Cars addToCart={addToCart} /> {/* Render the Featured Cars section */}
      <Testimonials /> {/* Render the Testimonials section */}
      <Team /> {/* Render the Team section */}
      <Blogs /> {/* Render the Recent Blogs section */}
    </div>
  )
}

export default HomePage;