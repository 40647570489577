import React from 'react';
import './CartSummary.css';
  

// Component that displays the summary of the shopping cart
const CartSummary = ({cart}) => {
 
    // Calculate the cart total
    const cartTotal = cart.reduce((total, { price = 0 }) => total + price, 0);

    // Calculate Insurance Fee
    const insuranceRate = 0.1; // 10% insurance rate
    const totalInsuranceFee = cartTotal * insuranceRate;

    // Calculate the total cost of items in the cart
    const totalCost = cartTotal + totalInsuranceFee;

    return ( 
        <div className='shop-summary'>

            {/* Display the sub total */}
            <div className='summary-item'>
                <div className='box name'>sub total</div>
                <div className='box value'>${cartTotal}</div>
            </div>

            {/* Display the insurance fee */}
            <div className='summary-item'>
                <div className='box name'>insurance fee</div>
                <div className='box value'>${totalInsuranceFee}</div>
            </div>

            {/* Display the total cost */}
            <div className='summary-item'>
                <div className='box name'>total</div>
                <div className='box value'>${totalCost}</div>
            </div>

        </div>
    )
}

export default CartSummary;