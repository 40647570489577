import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsMapFill } from 'react-icons/bs';

   
const contacts = [ 
    { 
        id: 1, 
        icon: <AiTwotonePhone />,
        title: 'contact',
        content: [
            {id: 1, value: '+123-456-789,'},
        ],
    },
    {
        id: 2,
        icon: <FaEnvelope />,
        title: 'email',
        content: [
            {id: 1, value: 'abc@gmail.com,'},
        ],
    },
    {
        id: 3,
        icon: <BsMapFill />,
        title: 'address',
        content: [
            {id: 1, value: 'karachi, pakistan'}
        ],
    },
 
];

export default contacts;