import React from 'react';
import './Cart.css';

import { PageTitle, Button, CartTitle, CartSummary } from '../../../components';

import { RiDeleteBin5Fill } from 'react-icons/ri';
import { BsCartFill } from 'react-icons/bs';

 

const Cart = ({ cart, removeFromCart }) => {

  return ( 
    <>
      {/* Page title */}
      <PageTitle title={'cart'} page={'cart'} />

      {/* Cart Section */}
      <section className='cart'>
        {cart.length === 0 ? (

          // Empty cart message
          <div className='no-cart-items'>
            <div className='empty-cart-msg'>
              <div className='icon'><BsCartFill /></div>
              <h3>your cart is currently empty</h3>
            </div>
            <Button link={'Car-List'} title={'return to cars'} />
          </div>

        ) : (
          <>
            {/* Shopping cart */}
            <div className='shopping-cart'>
              <div className='container'>
                {/* Cart title */}
                <CartTitle titles={['car', 'fuel type', 'price', 'action']} />
                <div className='box-container'>
                  {/* Render cart items */}
                  {cart.map((item) => {
                    return (
                      <div className='cart-item' key={item.id}>
                        <div className='box car'>
                          <img className='image' src={item.image} alt='' />
                          <div className='name'>{item.name}</div>
                        </div>
                        <div className='box fuel'>{item.details.fuelType}</div>
                        <div className='box price'>${item.price}</div>
                        <div className='box action'>
                          {/* Remove item button */}
                          <button type='submit' onClick={() => removeFromCart(item)}>
                            <RiDeleteBin5Fill className='icon delete' />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Cart total */}
            <div className='cart-total'>
              <CartSummary cart={cart} />
              {/* Proceed to checkout button */}
              <Button link={'checkout'} title={'proceed to checkout'} />
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default Cart;
