import React from 'react';
import './CartTitle.css';

  
// Component that displays the title for the shopping cart columns
const CartTitle = ({titles}) => {
  return (
 
    <div className='cartlist-title'> 
      {/* Render each title */} 
      {titles.map((title) => {
        return (
          <h3 key={title}>{title}</h3> 
        )
      })}
    </div>

  ) 
} 

export default CartTitle;