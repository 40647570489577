import { images } from '../constants';


const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'Best cars for the best journey',
        content: 'We provide Best cars with the best prices. We are expert in car rental. Enjoy your holidays with us. We make your drive memorable. We care for your safety.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Make your trip enjoyable',
        content: 'We provide Best cars with the best prices. We are expert in car rental. Enjoy your holidays with us. We make your drive memorable. We care for your safety.',
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Better care with best prices',
        content: 'We provide Best cars with the best prices. We are expert in car rental. Enjoy your holidays with us. We make your drive memorable. We care for your safety.',   
    },

];

export default home;