import React from 'react';
import './CarList.css';

import { PageTitle, PagesNo, Car, CarHeader, CarsSearch } from '../../../components';
import { cars } from '../../../constants';
import { useCarFilter } from '../../../utils';


  
const CarList = ({addToCart}) => {    
 
  const itemsPerPage = 4;

  // Fetch filtered and paginated car data using custom hook
  const {
    matchingCars,
    sortOption,
    currentPage,
    displayCars,
    displayedCars,
    handleFormSubmit,
    handleSort,
    totalPages,
    handlePageChange,
  } = useCarFilter(cars, itemsPerPage);

  return ( 
    <> 
      {/* Page title */}
      <PageTitle title={'car list'} page={'car list'} />

      {/* Cars search component */}
      <CarsSearch onFormSubmit={handleFormSubmit} />

      <section className='cars'>
        {/* Check if there are matching cars */}
        {matchingCars.length === 0 ? (
        // Render "No car found" message if no matching cars
        <div className='no-car-found'>No car found.</div>
        ) : (
        <>
          {/* Car header component */}
          <CarHeader 
            showing={displayedCars.length} 
            total={displayCars.length} 
            sortOption={sortOption} 
            handleSort={handleSort} 
          /> 
          <div className='car-container list'>
            {/* Render each car component */}
            {displayedCars.map((car) => {
              return(
                <Car
                key={car.id}  
                image={car.image}
                name={car.name}
                content={car.content} 
                price={car.price}
                specs={car.details}
                car={car}
                addToCart={addToCart} />
              )
            })
            } 
          </div>
          {/* pagination component */}
          <PagesNo currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
        </>
        )}
      </section>
    </>
  )
}

export default CarList;