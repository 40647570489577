import React from 'react';
import './PageTitle.css';

import { FaHome, FaAngleRight } from 'react-icons/fa'; 

import { Link } from 'react-router-dom'; 

    

const PageTitle = ({title, page}) => {
  return ( 
    
    <div className='page-title'> 

      {/* Main Title Container */}
      <div className='title'>
        <h2>{title}</h2>
      </div>  

      {/* HomePage Link and page Title Container */}
      <div className='link'>
        <Link to='/'><FaHome className='page-home' /><span>Home</span></Link> {/* Link to the home page */}
        <FaAngleRight className='icon' /> {/* Icon indicating a link */}
        <span className='page'>{page}</span> {/* Page Title */}
      </div>

    </div>
  )
}

export default PageTitle;