import React, {useState, useEffect} from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo, Button } from '../../components';
import { navbar } from '../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
   
  
      
const Header = () => { 

  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const {pathname} = useLocation();

  // Handle click on menu button
  const handleClick = () => {
    setClick(true); 
    setOpen(true); 
  }

  // Close mobile navbar
  const closeMenuBar = () => {
    setClick(false);
    setOpen(false); 
  }

  /* Close the Navbar between navigation */
  useEffect(() => {
    setOpen(false); 
  }, [ pathname ]);
     
  /* Manage On Scroll Functionality */
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  });

  /* Manage Sticky Header */
  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
        window.removeEventListener('scroll', stickyHeader);
    };
  });

  // Handle scroll event
  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);  
  };

  // Add active class to header on scroll
  const stickyHeader = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
  };


  return (  

    <nav className='header'>

      {/* 1st Header Container */}
      <div className='header-1'>

        {/* Header contacts */}
        <ul className='header-contacts'>
          <li><FaPhoneAlt className='icon' /><span>+111-222-333</span></li>
          <li><FaEnvelope className='icon' /><span className='gmail'>abc@gmail.com</span></li>
        </ul>

        {/* Social contacts */}
        <ul className='social-contacts'>
          <li><a href="/#"><FaFacebookF className='icon' /></a></li>
          <li><a href="/#"><FaTwitter className='icon' /></a></li>
          <li><a href="/#"><FaInstagram className='icon' /></a></li>
          <li><a href="/#"><FaLinkedin className='icon' /></a></li>
        </ul>
         
      </div> 

      {/* 2nd Header Container */}
      <div className='header-2'>

        {/* Mobile Navbar */}
        {(click && isOpen) && <MobileNavbar close={closeMenuBar} />}
 
        {/* Menu button */}
        <div className='icon menu-btn' id='menu' onClick={handleClick}><FaBars /></div>

        {/* Logo */}
        <Logo /> 

        <div className='container'>

          {/* Desktop Navbar */}
          <div className='navbar' >
            {
              navbar.map((item) => {
                return <HeaderItem key={item.id} {...item} />
              })
            }
          </div> 

          {/* Rent Car Button */}
          <Button link={'Car-List'} title={'rent car'} />

        </div>

      </div>

    </nav>
   )
 }
 
 export default Header;