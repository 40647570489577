/***** 
1)-useBlogFilter: A custom React Hook that facilitates filtering and pagination of a list of blogs based on selected category, tag, and search keyword. 
 * It manages state variables and provides functions for handling category, tag, and search changes, as well as search form submission and page navigation.

2)-handleCategory: Handles the change of selected category and updates the filtered blogs accordingly.
 * searchBlogsByCategory: Filters blogs by the specified category.

3)-handleTag: Handles the change of selected tag and updates the filtered blogs accordingly.
 * searchBlogsByTag: Filters blogs by the specified tag.

4)-handleSearch: Handles the change of search keyword and updates the filtered blogs accordingly.
 * searchBlogsByTitle: Filters blogs by the specified search keyword in the heading.
 * handleSearchSubmit: Handles the submission of the search form and navigates to the search results page.

5)-latestBlogs: Retrieves the three latest blogs from the given array of all blog content.

*****/ 
 

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


/***
 * A custom React Hook that facilitates filtering and pagination of a list of blogs based on selected category, tag, and search keyword.
 * initialBlogs - The initial array of blogs to filter.
 * itemsPerPage - The number of blogs to display per page.
 * Return An object with relevant state variables and functions for filtering and pagination.
 */

export const useBlogFilter = (initialBlogs, itemsPerPage) => {

  // Router navigation
  const navigate = useNavigate(); 
  
  // State variables 
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  // Get category, tag, and search params from the URL
  const { category, tag, search } = useParams();

  // Set selected category, tag, and search keyword based on URL params
  useEffect(() => {
    setSelectedCategory(category || '');
    setSelectedTag(tag || '');
    setSearchKeyword(search || '');
  }, [category, tag, search]);
    
  useEffect(() => {
    let matchingBlogs = initialBlogs;

    if (selectedCategory) {
      matchingBlogs = handleCategory(selectedCategory);
    }

    if (selectedTag) {
      matchingBlogs = handleTag(selectedTag);
    }

    if (searchKeyword) {
        matchingBlogs = handleSearch(searchKeyword);
    }

    if (matchingBlogs.length > 0) {
      setFilteredBlogs(matchingBlogs); 
    } else {
      setFilteredBlogs([]); 
    }

    setCurrentPage(1);

  }, [selectedCategory, selectedTag, searchKeyword, initialBlogs]);

  // Handle category change
  const handleCategory = (categoryTitle) => {
    setSelectedCategory(categoryTitle.toLowerCase());
    const matchingBlogs = searchBlogsByCategory(initialBlogs, categoryTitle);
    return matchingBlogs;
  };

  /**
   * Filter blogs by category.
   * blogs - The array of blogs to filter.
   * category - The category to filter by.
   * Return the filtered array of blogs.
  */
  const searchBlogsByCategory = (blogs, category) => {
    return blogs.filter((blog) => blog.category.toLowerCase() === category.toLowerCase());
  };

  // Handle tag change
  const handleTag = (tagTitle) => {
    setSelectedTag(tagTitle.toLowerCase());
    const matchingBlogs = searchBlogsByTag(initialBlogs, tagTitle);
    return matchingBlogs;
  };

  /**
   * Filter blogs by tag.
   * blogs - The array of blogs to filter.
   * tag - The tag to filter by.
   * Return The filtered array of blogs.
  */
  const searchBlogsByTag = (blogs, tag) => {
    return blogs.filter((blog) => {
      return blog.tags.some((tagObj) => tagObj.title.toLowerCase() === tag.toLowerCase());
    });
  };

  // Handle search keyword change
  const handleSearch = (searchValue) => { 
    setSearchKeyword(searchValue.toLowerCase());
    const matchingBlogs = searchBlogsByTitle(initialBlogs, searchValue);
    return matchingBlogs;
  };

  /**
   * Filter blogs by title.
   * blogs - The array of blogs to filter.
   * heading - The search keyword to filter by.
   * Return The filtered array of blogs.
  */
  const searchBlogsByTitle = (blogs, heading) => {
    const filteredBlogs = blogs.filter((blog) => blog.heading.toLowerCase().includes(heading));
    return filteredBlogs.length > 0 ? filteredBlogs : [];
  };

  // Handle search form submission
  const handleSearchSubmit = (selectedSearchQuery) => {
    navigate(`/Blogs/search/${selectedSearchQuery}`);
  };

  const displayBlogs = filteredBlogs.length > 0 ? filteredBlogs : initialBlogs;

  // Calculate start and end index based on current page and items per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the blogs to display on the current page
  const displayedBlogs = displayBlogs.slice(startIndex, endIndex);

  // Calculate total pages
  const totalPages = Math.ceil(displayBlogs.length / itemsPerPage);

  // Update current page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return {
    selectedCategory,
    selectedTag,
    filteredBlogs,
    searchKeyword, 
    currentPage,
    handleSearchSubmit,
    displayedBlogs,
    totalPages,
    handlePageChange,
  };
}; 

/**
 * Get the latest blogs.
 * blogContent - The array of all blog content.
 * Return The array of latest blogs.
*/
export const latestBlogs = (blogContent) => {

  // Sort blogs based on date in descending order
  const sortedBlogs = blogContent.slice().sort((a, b) => new Date(b.date) - new Date(a.date));

  // Extract the three latest blogs as copies
  const latestBlogs = sortedBlogs.slice(0, 3);
  return latestBlogs;
};