import { images } from '.'; 
 
const galleryContent = [
    {
        id: 1,
        image: images.gallery1,
        tag: 'convertible', 
        title: 'convertible 1',
    },
    {
        id: 2,
        image: images.gallery2,
        tag: 'suv',
        title: 'SUV 1',
    },
    {
        id: 3,
        image: images.gallery3,
        tag: 'convertible',
        title: 'convertible 2',
    },
    {
        id: 4,
        image: images.gallery4,
        tag: 'suv',
        title: 'SUV 2',
    },
    {
        id: 5,
        image: images.gallery5,
        tag: 'suv',
        title: 'SUV 3',
    },
    {
        id: 6,
        image: images.gallery6,
        tag: 'sports',
        title: 'sports car 1',
    },
    {
        id: 7,
        image: images.gallery7,
        tag: 'minivan',
        title: 'mini van 1',
    },
    {
        id: 8,
        image: images.gallery8,
        tag: 'sports',
        title: 'sports car 1',
    },
    {
        id: 9,
        image: images.gallery9,
        tag: 'minivan',
        title: 'mini van 2',
    },
    {
        id: 10,
        image: images.gallery10,
        tag: 'sports',
        title: 'sports car 3',
    },
];

export default galleryContent;