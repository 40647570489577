import React from 'react';
import './GalleryList.css';


// Gallery list component
const GalleryList = ({ id, title, active, setSelected }) => { 

  return (
    <li  
      key={id}
      className={active ? "button active" : "button"}
      onClick={() => setSelected(id)}>
      {title} {/* Display the title */}
    </li>
  );
}

export default GalleryList;